'use client';

import {useEffect, useState} from 'react';

const UseViewPortHeight = () => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => setHeight(window.innerHeight);
      setHeight(window.innerHeight);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return {height};
};

export default UseViewPortHeight;
