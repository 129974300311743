import {api} from '@/services';
import {createSlice} from '@reduxjs/toolkit';

const tokenSlice = createSlice({
  name: 'token',
  initialState: {
    token: null,
    userPhone: null,
    userPassword: null,
    favoritesToken: null,
    googleToken: null,
    facebookToken: null,
  },
  reducers: {
    resetToken: state => {
      state.token = null;
      state.favoritesToken = null;
    },
    setUserPhone: (state, action) => {
      state.userPhone = action.payload;
    },
    setUserPassowrd: (state, action) => {
      state.userPassword = action.payload;
    },
    setGoogleToken: (state, action) => {
      state.googleToken = action.payload;
    },
    setFacebookToken: (state, action) => {
      state.facebookToken = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(api.endpoints.login.matchFulfilled, (state, action) => {
      state.token = action.payload.access_token;
    });
    builder.addMatcher(
      api.endpoints.socialMediaAuth.matchFulfilled,
      (state, action) => {
        state.token = action.payload.access_token;
      },
    );
    builder.addMatcher(api.endpoints.signup.matchFulfilled, (state, action) => {
      state.userPhone = action.payload.data.attributes.phone_number;
    });
    builder.addMatcher(
      api.endpoints.verifyUserOtp.matchFulfilled,
      (state, action) => {
        state.userPhone = action.payload.data.attributes.phone_number;
      },
    );
    builder.addMatcher(api.endpoints.logout.matchFulfilled, resetToken);
    builder.addMatcher(
      api.endpoints.getOrCreateFavoritesList.matchFulfilled,
      (state, action) => {
        state.favoritesToken = action.payload.wishlistToken;
      },
    );
  },
});

export const {
  resetToken,
  setUserPhone,
  setUserPassowrd,
  setGoogleToken,
  setFacebookToken,
} = tokenSlice.actions;

export const tokenReducer = tokenSlice.reducer;
