import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isCartOpen: false,
};

export const cartActionsSlice = createSlice({
  name: 'cartActions',
  initialState,
  reducers: {
    handleCartPopUpState: (state, action) => {
      state.isCartOpen = action.payload;
    },
  },
});

export const {handleCartPopUpState} = cartActionsSlice.actions;
export const cartActionsReducer = cartActionsSlice.reducer;
