import {api} from '@/services';
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isGuestPaymentStep: false,
  guestPaymentId: null,
  isApplePayment: false,
  isToAddNewCard: false,
};

const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    setIsGuestPaymentStep(state, action) {
      state.isGuestPaymentStep = action.payload;
    },
    setGuestPaymentId(state, action) {
      state.guestPaymentId = action.payload;
    },
    setIsApplePayment(state, action) {
      state.isApplePayment = action.payload;
    },
    setIsAddingNewCard(state, action) {
      state.isToAddNewCard = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      api.endpoints.getClosestBranches.matchFulfilled,
      (state, action) => {
        state.closestBranch = action.payload;
      },
    );
  },
});

export const {
  setIsGuestPaymentStep,
  setGuestPaymentId,
  setIsApplePayment,
  setIsAddingNewCard,
} = deliverySlice.actions;
export default deliverySlice.reducer;
