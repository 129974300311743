import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  orderNumber: null,
  orderToken: null,
};

const lastPaidOrderSlice = createSlice({
  name: 'lastPaidOrder',
  initialState,
  reducers: {
    setOrderAttributes(state, action) {
      Object.assign(state, action.payload);
    },
  },
});

export const {setOrderAttributes} = lastPaidOrderSlice.actions;
export default lastPaidOrderSlice.reducer;
