import {api} from '@/services';
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  cart: null,
};

export const cartsSlice = createSlice({
  name: 'carts',
  initialState,
  reducers: {
    updateCart: (state, action) => {
      state.cart = action.payload;
    },
    resetCarts: state => {
      state.cart = null;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      api.endpoints.createCart.matchFulfilled,
      (state, action) => {
        state.cart = action.payload;
      },
    );
    builder.addMatcher(
      api.endpoints.getCart.matchFulfilled,
      (state, action) => {
        state.cart = action.payload;
      },
    );
    builder.addMatcher(
      api.endpoints.addToCart.matchFulfilled,
      (state, action) => {
        state.cart = action.payload;
      },
    );
    builder.addMatcher(
      api.endpoints.updateCartQuantity.matchFulfilled,
      (state, action) => {
        state.cart = action.payload;
      },
    );
    builder.addMatcher(
      api.endpoints.deleteFromCart.matchFulfilled,
      (state, action) => {
        state.cart = action.payload;
      },
    );
    builder.addMatcher(
      api.endpoints.reOrder.matchFulfilled,
      (state, action) => {
        state.cart = action.payload;
      },
    );
    builder.addMatcher(
      api.endpoints.associateCart.matchFulfilled,
      (state, action) => {
        state.cart = action.payload;
      },
    );
    builder.addMatcher(
      api.endpoints.nextCheckoutStep.matchFulfilled,
      (state, action) => {
        if (action.payload.data.attributes.state === 'complete') {
          state.cart = null;
        }
      },
    );
    builder.addMatcher(
      api.endpoints.completeCheckout.matchFulfilled,
      (state, action) => {
        if (action.payload.data.attributes.state === 'complete') {
          state.cart = null;
        }
      },
    );
  },
});

export const {updateCart, resetCarts} = cartsSlice.actions;
export const cartsReducer = cartsSlice.reducer;
