import {createSlice} from '@reduxjs/toolkit';

const initialState = [];

const recentlyViewedProductsSlice = createSlice({
  name: 'recentlyViewedProducts',
  initialState,
  reducers: {
    addProduct: (state, action) => {
      const product = action.payload;
      const existingIndex = state.findIndex(p => p.id === product.id);

      if (existingIndex !== -1) {
        state.splice(existingIndex, 1);
      } else if (state.length === 8) {
        state.pop();
      }

      state.unshift(product);
    },
  },
});

export const {addProduct} = recentlyViewedProductsSlice.actions;
export default recentlyViewedProductsSlice.reducer;
