'use client';

import {commonDisableCachingParameters} from '@/common';
import {getFavoritesToken, getNearestStoreData, getToken} from '@/selectors';
import {
  useAddItemToFavMutation,
  useGetOrCreateFavoritesListQuery,
  useRemoveItemFromFavMutation,
} from '@/services';
import debounce from 'lodash/debounce';
import {useLocale} from 'next-intl';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

const UseAddOrRemoveFav = () => {
  const favToken = useSelector(getFavoritesToken);
  const token = useSelector(getToken);
  const locale = useLocale();
  const [addItemToFav] = useAddItemToFavMutation();
  const [removeItemFromFav] = useRemoveItemFromFavMutation();
  const store = useSelector(getNearestStoreData);
  const {data: favData, isLoading: isFavProductsAreLoading} =
    useGetOrCreateFavoritesListQuery(
      {locale, branchId: store?.id},
      {skip: !token, ...commonDisableCachingParameters},
    );
  const [localFavData, setLocalFavData] = useState([]);

  useEffect(() => {
    if (favData) {
      setLocalFavData(favData.products);
    }
  }, [favData]);

  const applyOrRemoveFavItem = debounce(
    async (isProductInFavorites, variantId, productId) => {
      if (!isProductInFavorites) {
        const newFavItem = {product: {id: productId, wishedItemId: variantId}};
        setLocalFavData(prev => [...prev, newFavItem]);
        const response = await addItemToFav({
          token: favToken,
          itemId: variantId,
        });

        if (response?.error) {
          setLocalFavData(prev => prev.filter(item => item.id !== variantId));
        }
      } else {
        setLocalFavData(prev =>
          prev.filter(
            item => item.product.id !== isProductInFavorites.product.id,
          ),
        );

        const response = await removeItemFromFav({
          token: favToken,
          itemId: isProductInFavorites?.wishedItemId,
        });

        if (response?.error) {
          setLocalFavData(prev => [...prev, isProductInFavorites]);
        }
      }
    },
    300,
  );

  return {
    applyOrRemoveFavItem,
    localFavData,
    isFavProductsAreLoading,
    favToken,
    store,
  };
};

export default UseAddOrRemoveFav;
