import {useTranslations} from 'next-intl';
import {VideoRecorder} from '.';
import {Modal, ModalBody, ModalContent, ModalHeader} from '@heroui/react';
import {CustomButton} from '../common';

const VideoRecorderModal = ({
  isVideoModalOpen,
  setIsVideoModalOpen,
  setVideo,
}) => {
  const t = useTranslations();

  return (
    <Modal
      isOpen={isVideoModalOpen}
      size="4xl"
      closeButton={
        <CustomButton
          btnType="tertiary"
          value={'X'}
          onClickHandling={() => setIsVideoModalOpen(false)}
        />
      }
      classNames={{
        closeButton:
          'hidden sm:block !text-[22px] !text-persianIndigo hover:!bg-thistle font-main !p-0 !rounded-md mt-4',
        wrapper: 'items-center',
      }}
      onClose={() => setIsVideoModalOpen(false)}
      scrollBehavior="inside"
      className="mx-6 p-8">
      <ModalContent className="w-full">
        <ModalHeader className="flex flex-col gap-1 p-0 text-center font-main text-[22px] font-medium text-persianIndigo">
          {t('record_video')}
        </ModalHeader>
        <ModalBody className="w-full">
          <VideoRecorder
            setVideo={setVideo}
            setIsVideoModalOpen={setIsVideoModalOpen}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VideoRecorderModal;
