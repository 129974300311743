'use client';

import {
  useAddToCartMutation,
  useDeleteFromCartMutation,
  useUpdateCartQuantityMutation,
} from '@/services';
import {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import debounce from 'lodash/debounce';
import {usePathname} from '@/utils/navigation';
import {handleCartPopUpState} from '@/slices';
import {useMediaQuery} from 'react-responsive';
import {showErrorToast} from '@/common';
import {sendGTMEvent} from '@next/third-parties/google';
import {getToken, getUserData} from '@/selectors';

const useApplyCartActions = () => {
  const [addToCart, {isLoading: isAddingToCartLoading}] =
    useAddToCartMutation();
  const dispatch = useDispatch();
  const pathName = usePathname();
  const [updateLineItem] = useUpdateCartQuantityMutation();
  const [deleteFromCart] = useDeleteFromCartMutation();
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});
  const token = useSelector(getToken);
  const userData = useSelector(getUserData);

  const isCheckoutPage = useMemo(
    () => pathName.includes('checkout'),
    [pathName],
  );
  const applyCartAction = useCallback(
    async (action, variant, quantity = 0) => {
      if (action === 'add') {
        const response = await addToCart({
          variant_id: variant.id,
          quantity: 1,
        });
        if (response?.error) {
          showErrorToast(response?.error?.error);
        } else {
          const productLineItem = response?.data.lineItems?.find(
            item => item?.variant.id === variant.id,
          );
          sendGTMEvent({
            event: 'add_to_cart_trigger',
            product_name: productLineItem?.name,
            product_price: productLineItem?.price,
            product_currency: 'SAR',
            product_quantity: productLineItem?.quantity || 0,
            product_value: productLineItem?.total || 0,
            ...(token &&
              userData?.userName && {
                name: userData.userName,
                email: userData.userEmail,
                phone_number: userData.userPhone,
              }),
          });
          dispatch(handleCartPopUpState(true));
          if (!isCheckoutPage) {
            if (typeof window !== 'undefined' && isMobile) {
              window.scrollTo({top: 0, behavior: 'smooth'});
            }
          }
        }
      } else if (action === 'decrease') {
        if (quantity > 1) {
          await updateLineItem({
            line_item_id: variant.id,
            quantity: quantity - 1,
          });
        } else {
          await deleteFromCart(variant.id);
        }
      } else if (action === 'delete') {
        const response = await deleteFromCart(variant.id);
        if (!response.error) {
          sendGTMEvent({event: 'remove_from_cart_trigger'});
        }
      }
    },
    [addToCart, updateLineItem, deleteFromCart, isCheckoutPage, dispatch],
  );

  const debouncedApplyCartAction = useCallback(debounce(applyCartAction, 300), [
    applyCartAction,
  ]);

  return {applyCartAction, debouncedApplyCartAction, isAddingToCartLoading};
};

export default useApplyCartActions;
