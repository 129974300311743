'use client';

import {video} from '@/assets';
import {useTranslations} from 'next-intl';
import Image from 'next/image';
import {useEffect, useRef, useState} from 'react';
import {CustomButton} from '../common';
import {showErrorToast} from '@/common';

const VideoRecorder = ({setVideo, setIsVideoModalOpen}) => {
  const videoLiveRef = useRef(null);
  const videoRecordedRef = useRef(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recording, setRecording] = useState(false);
  const [videoURL, setVideoURL] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);
  const [stream, setStream] = useState(null);
  const t = useTranslations();

  const startRecording = async () => {
    if (videoLiveRef.current) {
      videoLiveRef.current.srcObject = null;
    }
    setVideoURL(null);

    if (!navigator.mediaDevices || !MediaRecorder) {
      showErrorToast(t('browser_not_supported'));
      return;
    }

    try {
      const userStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setStream(userStream);

      if (videoLiveRef.current) {
        videoLiveRef.current.srcObject = userStream;
      }

      if (!MediaRecorder.isTypeSupported('video/webm')) {
        showErrorToast(t('video_is_not_supported'));
        return;
      }

      const recorder = new MediaRecorder(userStream, {mimeType: 'video/webm'});

      let recordedChunks = [];

      recorder.ondataavailable = event => {
        if (event.data.size > 0) {
          recordedChunks.push(event.data);
        }
      };

      recorder.onstop = () => {
        const blob = new Blob(recordedChunks, {type: 'video/webm'});
        setVideoBlob(blob.slice(0, blob.size, 'video/mp4'));
        const url = URL.createObjectURL(blob);
        setVideoURL(url);
      };

      recorder.onerror = error => {
        showErrorToast(t('error_recording_video'));
        console.error('Recording error:', error);
      };

      setMediaRecorder(recorder);
      recorder.start();
      setRecording(true);
    } catch (error) {
      showErrorToast(t('error_accessing_camera'));
      console.error('Camera access error:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
      if (videoLiveRef.current) {
        videoLiveRef.current.srcObject = null;
      }
      setStream(null);
    }
  };

  const onHandelingRecordModalValues = () => {
    if (!videoBlob && !recording) {
      return t('record');
    } else if (recording) {
      return t('stop');
    } else {
      return t('upload');
    }
  };

  const onHandelingRecordModalActions = () => {
    if (!videoBlob && !recording) {
      return startRecording;
    } else if (recording) {
      return stopRecording;
    } else {
      return () => setIsVideoModalOpen(false);
    }
  };

  const uploadVideo = blob => {
    if (!blob) {
      showErrorToast(t('no_video_to_upload'));
      return;
    }

    const videoFile = new File([blob], 'video-title.mp4', {
      type: 'video/mp4',
    });

    setVideo(videoFile);
  };

  useEffect(() => {
    if (videoBlob) {
      uploadVideo(videoBlob);
    }
  }, [videoBlob]);

  useEffect(() => {
    if (videoLiveRef.current && stream) {
      videoLiveRef.current.srcObject = stream;
    }
  }, [stream]);

  return (
    <div className="flex flex-col items-center">
      <div>
        {videoURL && !recording ? (
          <video
            ref={videoRecordedRef}
            src={videoURL}
            controls
            playsInline
            id="videoRecorded"
            className="h-[17rem] w-[37.5rem]"
          />
        ) : recording ? (
          <video
            ref={videoLiveRef}
            autoPlay
            muted
            playsInline
            id="videoLive"
            className="h-[17rem] w-[37.5rem]"
          />
        ) : (
          <div className="flex h-[13rem] items-center justify-center bg-transparent xl:w-[39rem]">
            <Image src={video} alt="video" />
          </div>
        )}
      </div>
      <CustomButton
        onClickHandling={onHandelingRecordModalActions()}
        value={onHandelingRecordModalValues()}
        btnStyles="mt-2 w-full sm:w-[50%]"
        btnType={recording ? 'secondary' : 'primary'}
      />
      {!recording && videoBlob && (
        <CustomButton
          onClickHandling={startRecording}
          value={t('redo')}
          btnType="tertiary"
          btnStyles="mt-2 w-full sm:w-[50%] text-[12px] underline hover:!bg-white"
        />
      )}
    </div>
  );
};

export default VideoRecorder;
