import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  guestCredentials: {
    guestPhoneNumber: null,
    guestFullName: null,
    isDataValid: false,
  },
};

export const guestSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    updateGuestCredentials: (state, action) => {
      state.guestCredentials = action.payload;
    },
    resetGuestCredentials: state => {
      state.guestCredentials = {
        guestPhoneNumber: null,
        guestFullName: null,
        isDataValid: false,
      };
    },
  },
});

export const {updateGuestCredentials, resetGuestCredentials} =
  guestSlice.actions;
export const guestReducer = guestSlice.reducer;
