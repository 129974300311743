import {api} from '@/services';
import {createSlice} from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: {
      userName: null,
      userEmail: null,
      userPhone: null,
    },
  },
  reducers: {
    resetUserData: state => {
      state.userData = {
        userName: null,
        userEmail: null,
        userPhone: null,
      };
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      api.endpoints.getUserWithAddresses.matchFulfilled,
      (state, action) => {
        state.userData = {
          userName: action.payload.user.firstName,
          userEmail: action.payload.user.email,
          userPhone: action.payload.user.phoneNumber,
        };
      },
    );
    builder.addMatcher(api.endpoints.logout.matchFulfilled, resetUserData);
  },
});

export const {resetUserData} = userSlice.actions;

export const userReducer = userSlice.reducer;
