'use client';

import {useLocale, useTranslations} from 'next-intl';
import {useEffect, useMemo, useState} from 'react';
import ImageCarousel from './ImageCarousel';
import ProductDetails from './ProductDetails';
import RelatedProducts from './RelatedProducts';
import RecentlyViewed from './RecentlyViewed';
import {useGetSuggestedProductsQuery} from '@/services';
import {useDispatch, useSelector} from 'react-redux';
import {addProduct} from '@/slices/recentlyViewedProductsSlice';
import {CustomButton, LoadingView} from '../common';
import {
  commonDisableCachingParameters,
  convertEnglishNumbersToArabic,
  renderStars,
  transformString,
} from '@/common';
import {Reviews} from '.';
import {getCart, getNearestStoreData, getToken, getUserData} from '@/selectors';
import {sendGTMEvent} from '@next/third-parties/google';
import Link from 'next/link';

const ProductScreen = ({product}) => {
  const locale = useLocale();
  const t = useTranslations();
  const dispatch = useDispatch();
  const cart = useSelector(getCart);
  const userData = useSelector(getUserData);
  const token = useSelector(getToken);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);

  const store = useSelector(getNearestStoreData);

  const {
    data: relatedProducts,
    isLoading: isGettingSuggestedProductsLoading,
    error: getSuggestedProductsError,
  } = useGetSuggestedProductsQuery(
    {
      params: {
        include: 'images',
        locale,
        'filter[taxons]': product?.taxonInfo?.[0]?.id,
        'filter[id_not_eq]': product?.productInfo?.id,
        'filter[branches]': store?.id,
      },
    },
    commonDisableCachingParameters,
  );

  useEffect(() => {
    if (product) {
      dispatch(addProduct(product?.productInfo));
    }
  }, [product, dispatch]);

  const productLineItem = useMemo(() => {
    return cart?.lineItems?.find(
      item => item?.name === product?.productInfo?.name,
    );
  }, [cart, product]);

  useEffect(() => {
    if (product?.productInfo?.name) {
      sendGTMEvent({
        event: 'view_product_trigger',
        product_name: product?.productInfo.name,
        product_price: product?.productInfo.price,
        product_currency: 'SAR',
        product_quantity: productLineItem?.quantity || 0,
        product_value: productLineItem?.total || 0,
        ...(token &&
          userData?.userName && {
            name: userData.userName,
            email: userData.userEmail,
            phone_number: userData.userPhone,
          }),
      });
    }
  }, [product, token, userData]);

  return (
    <>
      <div className="xl:mx-[4rem]">
        <div className="flex flex-col pt-8 sm:flex-row sm:items-start sm:justify-between sm:pb-[6rem]">
          <div className="relative mx-6 mb-8 flex items-center space-x-1 font-main text-[12px] text-persianIndigo sm:hidden">
            <Link href={`/${locale}`}>
              <span className="cursor-pointer">{t('home')}</span>
            </Link>
            <span>&gt;</span>
            <Link
              href={`/${locale}/category/${product?.taxonInfo[0]?.permaLink}/${product?.taxonInfo[0]?.id}`}>
              <span className="cursor-pointer capitalize">
                {transformString(
                  product?.taxonInfo[0]?.prettyName.replace(' All ->', ''),
                )}
              </span>
            </Link>
          </div>{' '}
          <div className="px-6 sm:hidden">
            <div className="mb-4 flex justify-between">
              <p className="font-main text-[16px] font-medium text-persianIndigo sm:text-[20px] xl:text-[1.5vw]">
                {product?.productInfo?.name}
              </p>
              <p className="font-main text-[18px] font-medium text-persianIndigo sm:text-[16px] xl:text-[1.1vw]">
                {convertEnglishNumbersToArabic(
                  Number(product?.productInfo?.price),
                  locale,
                )}{' '}
                {t('sar')}
              </p>
            </div>
            {parseInt(product?.totalRating) > 0 && (
              <div className="mt-1 flex flex-col">
                <div className="flex w-fit">
                  {renderStars(product?.totalRating, 1)}
                  <span className="ml-2 text-persianIndigo">
                    {product?.totalRating}
                  </span>
                </div>
                <CustomButton
                  btnType="tertiary"
                  btnStyles={
                    'hover:!bg-transparent !px-0 justify-start text-[12px] text-persianIndigo underline font-secondary w-[3.813rem] '
                  }
                  onClickHandling={() => setModalVisible(true)}
                  value={`${
                    product?.totalReviews &&
                    parseInt(product.totalReviews) === 0
                      ? t('no')
                      : product?.totalReviews
                  } ${parseInt(product?.totalReviews) === 1 ? t('review') : t('reviews')}`}
                />
              </div>
            )}
          </div>
          <div
            className={`w-full sm:sticky sm:top-[195px] sm:h-full sm:w-1/2 ${
              selectedVariant?.attributes?.images?.length > 0 ||
              product?.productImages?.length > 0
                ? ''
                : 'flex h-[15rem] items-center justify-center'
            }`}>
            {selectedVariant?.attributes?.images?.length > 0 ||
            product?.productImages?.length > 0 ? (
              <ImageCarousel
                locale={locale}
                product={product}
                selectedVariant={
                  selectedVariant?.attributes?.images?.length > 0
                    ? selectedVariant
                    : product
                }
              />
            ) : (
              <div className="font-secondary">
                {t('no_images_for_this_product')}
              </div>
            )}
          </div>
          <div
            className={`${locale === 'ar' ? 'sm:pl-6 xl:pl-10' : 'sm:pr-6 xl:pr-16'} w-full sm:w-1/2`}>
            <ProductDetails
              product={product}
              t={t}
              selectedVariant={selectedVariant}
              setSelectedVariant={setSelectedVariant}
            />
          </div>
        </div>
        <hr className="mx-6 mb-4 mt-6 block border-0 border-t border-thistle sm:mx-0 sm:hidden sm:w-[94%] xl:w-full" />

        <RelatedProducts
          t={t}
          locale={locale}
          products={relatedProducts}
          category={product?.taxonInfo?.[0]?.permaLink}
        />
        <RecentlyViewed t={t} locale={locale} />
      </div>
      <Reviews
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        product={product}
      />
    </>
  );
};

export default ProductScreen;
