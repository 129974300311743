'use client';

import Loader from 'nextjs-toploader';
import {usePathname} from 'next/navigation';
import {useEffect} from 'react';
import NProgress from 'nprogress';

const TopLoader = props => {
  const pathname = usePathname();

  useEffect(() => {
    NProgress.done();
  }, [pathname]);

  return <Loader {...props} />;
};

export default TopLoader;
