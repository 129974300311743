import {api} from '@/services';
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  deliveryMethodId: 1,
  nearestStore: null,
  userCoords: {
    lat: null,
    lng: null,
  },
  nearestStoreAddress: null,
  deliveryZoneId: null,
  prevBranchId: null,
};

export const deliveryMethodSlice = createSlice({
  name: 'deliveryMethod',
  initialState,
  reducers: {
    setDeliveryMethodId: (state, action) => {
      state.deliveryMethodId = action.payload;
    },
    updateUserCoords: (state, action) => {
      state.userCoords = action.payload;
    },
    setPrevBranchId: (state, action) => {
      state.prevBranchId = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      api.endpoints.getNearestStore.matchFulfilled,
      (state, action) => {
        state.nearestStore = action.payload;
      },
    );
  },
});

export const {
  SwitchToPickUp,
  SwitchToDelivery,
  updateUserCoords,
  setPrevBranchId,
  setDeliveryMethodId,
} = deliveryMethodSlice.actions;
export const deliveryMethodReducer = deliveryMethodSlice.reducer;
