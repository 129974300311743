'use client';

import {Input, Textarea} from '@heroui/react';
import {useFormik} from 'formik';
import {useLocale, useTranslations} from 'next-intl';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {BsCameraVideo} from 'react-icons/bs';
import {HiMiniLink} from 'react-icons/hi2';
import {CustomButton} from '../common';

const GiftCardForm = ({
  handleRecordVideoClick,
  handlePasteLinkClick,
  formData,
  onFormChange,
  isCheckout,
  setIsGiftCardShown,
}) => {
  const t = useTranslations();
  const [showSuggestedMessages, setShowSuggestedMessages] = useState(false);
  const locale = useLocale();
  const suggestedMessages = [
    'i_love_you',
    'happy_congratulations',
    'happy_birthday',
    'happy_mother_day',
  ];

  const validationSchema = Yup.object().shape({
    to: Yup.string(),
    from: Yup.string(),
    message: Yup.string().required(t('enter_message')),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values, {resetForm}) => {
      onFormChange(values);
    },
  });

  useEffect(() => {
    onFormChange(formik.values);
  }, [formik.values, onFormChange]);

  const handleSuggestedMessageClick = message => {
    formik.setFieldValue('message', t(message));
    setShowSuggestedMessages(false);
  };

  return (
    <div className={`${isCheckout ? 'xl:w-2/3' : 'xl:w-1/2'}`}>
      {!isCheckout && (
        <p className="mb-2 block text-center font-secondary text-[14px] font-normal text-persianIndigo sm:hidden xl:text-[1vw]">
          {t('add_your_message')}
        </p>
      )}

      <div
        className={`${isCheckout ? '' : 'min-h-[17rem] border border-thistle p-2'} w-full`}>
        <form onSubmit={formik.handleSubmit} className="mb-3">
          <div className="mb-3">
            <Input
              id="to"
              name="to"
              type="text"
              radius="sm"
              variant="bordered"
              placeholder={`${t('optional')}`}
              onChange={formik.handleChange}
              value={formik.values.to}
              startContent={
                <p className="font-main text-[14px] font-medium italic text-persianIndigo sm:text-[12px]">
                  {t('to')}:
                </p>
              }
              className="h-[2rem] w-full placeholder:font-main placeholder:text-[15px] placeholder:font-medium placeholder:italic placeholder:text-persianIndigo"
              classNames={{
                label: [
                  'sm:text-[12px]',
                  '!text-persianIndigo',
                  'font-secondary',
                ],
                input: [
                  'placeholder:text-thistle',
                  'placeholder:font-main',
                  'sm:placeholder:text-[12px] xl:placeholder:text-[0.6vw]',
                  'pb-[5px]',
                  'focus:placeholder:text-thistle',
                  'focus:border-thistle',
                  'placeholder:italic',
                  'text-persianIndigo',
                  'text-[14px] sm:text-[12px] xl:text-[0.8vw]',
                  'font-secondary',
                  'pt-1',
                ],
                innerWrapper: 'bg-transparent',
                inputWrapper: [
                  '!border-1',
                  '!border-thistle',
                  'focus:border-1',
                  'focus:border-thistle',
                  '.group[data-hover=true] .group-data-[hover=true]:border-thistle',
                ],
              }}
            />
          </div>
          <div className="mb-3">
            <Textarea
              id="message"
              variant="bordered"
              radius="sm"
              name="message"
              placeholder={t('message')}
              onChange={e => {
                formik.handleChange(e);
                if (e.target.value === '') {
                  formik.setFieldValue('message', '');
                }
              }}
              onKeyDown={e => {
                if (
                  formik.values.message.length >= 100 &&
                  e.key !== 'Backspace' &&
                  e.key !== 'Delete'
                ) {
                  e.preventDefault();
                  formik.setFieldValue(
                    'warningMessage',
                    t('you_have_reached_character_limit_of_100'),
                  );
                } else {
                  formik.setFieldValue('warningMessage', '');
                }
              }}
              value={formik.values.message}
              maxLength={100}
              className="w-full"
              classNames={{
                label: [
                  'sm:text-[12px]',
                  '!text-persianIndigo',
                  'font-secondary',
                ],
                input: [
                  'placeholder:text-persianIndigo',
                  'placeholder:font-main',
                  'focus:placeholder:text-persianIndigo',
                  'focus:border-thistle',
                  'text-persianIndigo',
                  'sm:placeholder:text-[12px] xl:placeholder:text-[0.7vw]',
                  'text-[14px] sm:text-[12px] xl:text-[0.8vw]',
                  'font-secondary',
                ],
                innerWrapper: 'bg-transparent',
                inputWrapper: [
                  '!border-1',
                  '!border-thistle',
                  'focus:border-1',
                  'focus:border-thistle',
                  '.group[data-hover=true] .group-data-[hover=true]:border-thistle',
                ],
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.message && formik.errors.message && (
              <div className="text-red-500 sm:ml-2 sm:text-[10px] xl:ml-0 xl:text-[0.7vw]">
                {formik.errors.message}
              </div>
            )}
            {formik.values.warningMessage && (
              <p className="text-red-500 sm:ml-2 sm:text-[10px] xl:ml-0 xl:text-[0.7vw]">
                {formik.values.warningMessage}
              </p>
            )}
          </div>
          <div className="mb-3 flex w-full items-center justify-between">
            <p className="font-secondary text-[14px] font-normal text-persianIndigo sm:text-[12px] xl:text-[0.7vw]">
              {t('not_sure_what_to_say')}
            </p>
            <button
              type="button"
              className="font-secondary text-[14px] font-normal text-persianIndigo underline sm:text-[12px] xl:text-[0.7vw]"
              onClick={() => setShowSuggestedMessages(!showSuggestedMessages)}>
              {t('try_suggested_message')}
            </button>
          </div>
          {showSuggestedMessages && (
            <div
              className={`${locale === 'ar' ? 'left-[1rem] xl:left-[6rem]' : 'right-[1rem] xl:right-[6rem]'} absolute z-10 w-[15rem] overflow-y-auto rounded-md border border-thistle bg-white shadow-lg sm:h-[11rem] xl:h-[12.3rem]`}>
              {suggestedMessages.map((message, index) => (
                <div
                  key={index}
                  className="cursor-pointer p-3 font-secondary text-persianIndigo transition hover:bg-thistle sm:text-[12px] xl:text-[16px]"
                  onClick={() => handleSuggestedMessageClick(message)}>
                  {t(message)}
                </div>
              ))}
            </div>
          )}
          <div className="mb-3">
            <Input
              id="from"
              radius="sm"
              variant="bordered"
              name="from"
              type="text"
              placeholder={`${t('optional')}`}
              onChange={formik.handleChange}
              value={formik.values.from}
              startContent={
                <p className="font-main text-[14px] font-medium italic text-persianIndigo sm:text-[12px] xl:text-[0.7vw]">
                  {t('from')}:
                </p>
              }
              className="h-[2rem] w-full placeholder:font-main placeholder:text-[15px] placeholder:font-medium placeholder:italic placeholder:text-persianIndigo"
              classNames={{
                label: [
                  'sm:text-[10px] xl:text-[12px]',
                  '!text-persianIndigo',
                  'font-secondary',
                ],
                input: [
                  'placeholder:text-thistle',
                  'sm:placeholder:text-[12px] xl:placeholder:text-[0.7vw]',
                  'pb-[5px]',
                  'placeholder:italic',
                  'placeholder:font-main',
                  'focus:placeholder:text-thistle',
                  'focus:border-thistle',
                  'text-persianIndigo',
                  'text-[14px] sm:text-[12px] xl:text-[0.8vw]',
                  'font-secondary',
                  'pt-1',
                ],
                innerWrapper: 'bg-transparent',
                inputWrapper: [
                  '!border-1',
                  '!border-thistle',
                  'focus:border-1',
                  'focus:border-persianIndigo',
                  '.group[data-focus=true] .group-data-[focus=true]:border-thsitle',
                ],
              }}
            />
          </div>
        </form>
        <div className="flex justify-center">
          <CustomButton
            btnType="tertiary"
            className="font-secondary text-[14px] font-normal text-persianIndigo underline sm:text-[12px] xl:text-[0.9vw]"
            value={t('apply_message_to_gift_card')}
            onClickHandling={() => setIsGiftCardShown(true)}
          />
        </div>
        {!isCheckout && (
          <hr className="mt-2 border-0 border-t border-dashed border-lightGray" />
        )}
        <div className="mt-2 flex w-full items-center justify-between">
          <div className="flex items-center justify-center gap-1">
            <BsCameraVideo className="text-persianIndigo sm:mb-1 sm:text-[14px] xl:text-[1.2vw]" />
            <button
              type="button"
              className="font-secondary text-[14px] font-normal text-persianIndigo sm:text-[12px] xl:text-[0.9vw]"
              onClick={handleRecordVideoClick}>
              {t('record_video')}
            </button>
          </div>
          <p className="font-secondary text-[14px] font-normal text-persianIndigo sm:text-[12px] xl:text-[0.9vw]">
            {t('or')}
          </p>
          <div className="flex items-center justify-center gap-1">
            <HiMiniLink className="text-persianIndigo sm:mb-1 sm:text-[14px] xl:text-[1.2vw]" />
            <button
              type="button"
              className="font-secondary text-[14px] font-normal text-persianIndigo sm:text-[12px] xl:text-[0.9vw]"
              onClick={handlePasteLinkClick}>
              {t('paste_link')}
            </button>
          </div>
        </div>
      </div>
      {!isCheckout && (
        <p className="mt-3 hidden text-center font-secondary text-[14px] font-normal text-persianIndigo sm:block xl:text-[1vw]">
          {t('add_your_message')}
        </p>
      )}
    </div>
  );
};

export default GiftCardForm;
