'use client';
import {getCart} from '@/selectors';
import {useRemoveCouponCodeMutation} from '@/services';
import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

const UseHandleHiddenNavbarActions = pathName => {
  const cart = useSelector(getCart);
  const [deleteCouponCode] = useRemoveCouponCodeMutation();

  const isCheckoutLinkPage = useMemo(
    () => pathName === '/orders/payment',
    [pathName],
  );
  const isRatingPage = useMemo(
    () => /orders\/[^/]+\/rating/.test(pathName),
    [pathName],
  );
  const isReceiverInfoFormPage = useMemo(
    () => pathName.includes('receiverInfoForm'),
    [pathName],
  );
  const isCheckoutPage = useMemo(
    () => pathName.includes('checkout'),
    [pathName],
  );
  const notToShowNavBar = useMemo(
    () => !isCheckoutLinkPage && !isRatingPage && !isReceiverInfoFormPage,
    [isCheckoutLinkPage, isRatingPage, isReceiverInfoFormPage],
  );

  useEffect(() => {
    if (cart?.couponCodes?.length > 0 && !isCheckoutPage) {
      deleteCouponCode(cart?.couponCodes?.[0]?.code);
    }
  }, [cart, isCheckoutPage]);

  return {isCheckoutPage, notToShowNavBar};
};

export default UseHandleHiddenNavbarActions;
