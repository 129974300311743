'use client';
import useEmblaCarousel from 'embla-carousel-react';
import Image from 'next/image';
import {useState} from 'react';
import {convertEnglishNumbersToArabic} from '@/common/helpers';
import {useLocale} from 'next-intl';
import {Tab, Tabs} from '@heroui/react';

const AddOns = ({addOns, t, onHandlingSelectedAddons}) => {
  const [emblaRef] = useEmblaCarousel();
  const locale = useLocale();
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);

  const handleSelectAddOn = addon => {
    setSelectedAddOns(prevSelectedAddOns => {
      const isSelected = prevSelectedAddOns.some(
        selected => selected.id === addon.id,
      );

      const updatedAddOns = isSelected
        ? prevSelectedAddOns.filter(selected => selected.id !== addon.id)
        : [...prevSelectedAddOns, addon];

      onHandlingSelectedAddons(updatedAddOns);
      return updatedAddOns;
    });
  };

  return (
    addOns?.taxons?.length > 0 && (
      <div className="mb-3 w-full px-6 sm:px-0">
        <div className="mb-4 me-8 flex items-center justify-between">
          <p className="font-main font-medium text-persianIndigo sm:font-secondary sm:text-[14px] sm:font-normal xl:text-[1vw]">
            {t('add_ons')}
          </p>
        </div>
        <Tabs
          aria-label="Options"
          variant="underlined"
          selectedKey={selectedTab}
          fullWidth
          onSelectionChange={setSelectedTab}
          classNames={{
            tabContent:
              'text-[13px] md:text-[16px]  group-data-[selected=true]:text-persianIndigo font-secondary font-normal text-persianIndigo',
            tabList: 'gap-0 border-b-1 border-thistle pb-[15px]',
            cursor: 'w-[102%] bottom-[-15px] bg-persianIndigo',
          }}>
          {addOns?.taxons?.map(taxon => (
            <Tab key={taxon.id} title={taxon.name}>
              <div className="flex items-center justify-between">
                <div className="embla overflow-hidden" ref={emblaRef}>
                  <div
                    className={`${
                      locale === 'ar' ? 'flex-row-reverse' : 'flex-row'
                    } embla__container flex gap-2 sm:gap-4`}>
                    {taxon.products?.map((product, index) => {
                      const imageSrc =
                        product?.images?.[0]?.attributes?.original_url;
                      const isSelected = selectedAddOns.find(
                        item => item.id === product.variants?.[0]?.id,
                      );

                      return (
                        <div
                          className={`embla__slide cursor-pointer`}
                          key={index}
                          onClick={() =>
                            handleSelectAddOn(product.variants?.[0])
                          }>
                          <div
                            className={`${isSelected ? 'border-royalPurple' : 'border-transparent'} mb-2 flex h-[8.938rem] w-[8.188rem] items-center justify-center rounded-[8px] border-2 sm:h-[11.5rem] sm:w-[10.5rem]`}>
                            <Image
                              src={imageSrc}
                              width={600}
                              height={400}
                              alt="add-on"
                              className="h-full w-full rounded-[8px] object-fill"
                            />
                          </div>
                          <div className="flex items-center justify-between">
                            <p className="font-main text-[14px] font-normal text-persianIndigo sm:text-[12px] xl:text-[0.8vw]">
                              {convertEnglishNumbersToArabic(
                                Number(product.price),
                                locale,
                              )}{' '}
                              {t('sar')}
                            </p>
                          </div>
                          <p className="font-secondary text-[12px] font-normal text-persianIndigo sm:text-[14px] xl:text-[1vw]">
                            {product.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>
    )
  );
};

export default AddOns;
