'use client';

import {commonDisableCachingParameters} from '@/common';
import {getCart, getDeliveryMethodId, getNearestStoreData} from '@/selectors';
import {useCreateCartMutation, useGetCartQuery} from '@/services';
import {setPrevBranchId} from '@/slices';
import {useLocale} from 'next-intl';
import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const UseGetOrCreateCart = () => {
  const cart = useSelector(getCart);
  const store = useSelector(getNearestStoreData);
  const dispatch = useDispatch();
  const locale = useLocale();
  const deliveryMethodId = useSelector(getDeliveryMethodId);

  const [createCart] = useCreateCartMutation();

  const {isLoading: isOrderLoading, error: getCartError} = useGetCartQuery(
    {
      id: cart?.id,
      locale,
      branch_id: store?.id,
    },
    {
      ...commonDisableCachingParameters,
      skip: !cart,
    },
  );

  const isToCreateCart = useMemo(
    () => !cart && store?.id && deliveryMethodId,
    [cart, store],
  );

  const handleCartCreation = async () => {
    if (isToCreateCart) {
      await createCart({
        order: {
          line_items: [],
        },
        branch_id: store?.id,
        shopping_method_id: deliveryMethodId,
      });

      dispatch(setPrevBranchId(store?.id));
    }
  };

  useEffect(() => {
    handleCartCreation();
  }, [isToCreateCart]);

  if (getCartError?.status === 404) {
    handleCartCreation();
  }

  return [cart, isOrderLoading];
};

export default UseGetOrCreateCart;
