'use client';

import {getCurrentUserCoords} from '@/selectors';
import {updateUserCoords} from '@/slices';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const useGetCurrentUserLocation = () => {
  const disptach = useDispatch();
  const userCoords = useSelector(getCurrentUserCoords);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          const {latitude, longitude} = position.coords;
          if (userCoords?.userLat && userCoords?.userLng) {
            return;
          }
          disptach(
            updateUserCoords({
              userLat: latitude,
              userLng: longitude,
            }),
          );
        },
        error => {
          console.log('Error getting user location', error);
          if (userCoords?.userLat && userCoords?.userLng) {
            return;
          }
          disptach(
            updateUserCoords({
              userLat: 24.7136,
              userLng: 46.6753,
            }),
          );
        },
      );
    } else {
      if (userCoords?.userLat && userCoords?.userLng) {
        return;
      }
      disptach(
        updateUserCoords({
          userLat: 24.7136,
          userLng: 46.6753,
        }),
      );
    }
  }, []);

  return {userCoords};
};

export default useGetCurrentUserLocation;
