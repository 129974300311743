import {
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from '@heroui/react';
import {useTranslations} from 'next-intl';
import {CustomButton} from '../common';

const LinkModal = ({
  isLinkModalOpen,
  setIsLinkModalOpen,
  link,
  handleLinkChange,
}) => {
  const t = useTranslations();

  return (
    <Modal
      isOpen={isLinkModalOpen}
      size="md"
      onClose={() => setIsLinkModalOpen(false)}
      closeButton={
        <CustomButton
          btnType="tertiary"
          value={'X'}
          onClickHandling={() => setIsLinkModalOpen(false)}
        />
      }
      className="mx-6 p-6"
      classNames={{
        closeButton:
          'hidden !text-[22px] !text-persianIndigo hover:!bg-thistle font-main !p-0 !rounded-md mt-4 sm:block',
        wrapper: 'items-center',
      }}>
      <ModalContent>
        <ModalHeader className="flex flex-col text-center font-main text-[22px] font-medium text-persianIndigo">
          {t('paste_your_link')}
        </ModalHeader>
        <ModalBody>
          <Input
            variant="underlined"
            type="text"
            value={link}
            onChange={handleLinkChange}
            placeholder={t('link_here')}
            className="mb-4 font-secondary text-[16px] font-normal placeholder:text-thistle"
            classNames={{
              label: '!text-thistle',
              inputWrapper:
                '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo h-8',
              input: '!text-persianIndigo placeholder:text-thistle',
            }}
          />
        </ModalBody>

        <CustomButton
          onClickHandling={() => setIsLinkModalOpen(false)}
          isDisabled={!link}
          btnStyles="w-[20rem] mx-auto"
          value={t('continue')}
        />
      </ModalContent>
    </Modal>
  );
};

export default LinkModal;
