import {Button} from '@heroui/react';
import React from 'react';

const CustomButton = ({
  btnActionType = 'button',
  btnStyles,
  value,
  onClickHandling,
  isLoading,
  iconOnly = false,
  btnType = 'primary',
  ...otherProps
}) => {
  const getButtonType = () => {
    switch (btnType) {
      case 'primary':
        return 'solid';
      case 'secondary':
        return 'bordered';
      case 'tertiary':
        return 'light';
    }
  };
  const getDefaultStyles = () => {
    switch (btnType) {
      case 'primary':
        return 'bg-royalPurple text-white';
      case 'secondary':
        return 'bg-transparent border-royalPurple text-royalPurple';
      case 'tertiary':
        return 'text-royalPurple';
    }
  };

  return (
    <Button
      type={btnActionType}
      variant={getButtonType(btnType)}
      className={`cursor-pointer font-main text-[16px] font-normal ${getDefaultStyles()} ${btnStyles} ${iconOnly ? 'rounded-[50%]' : 'rounded-[5px]'}`}
      onPress={onClickHandling}
      isLoading={isLoading}
      isIconOnly={iconOnly}
      {...otherProps}>
      {value}
    </Button>
  );
};

export default CustomButton;
