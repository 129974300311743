import {logo} from '@/assets';
import Image from 'next/image';

const LoadingView = ({isTabs}) => {
  return (
    <div
      className={`${isTabs ? 'flex h-[65vh] grow items-center justify-center bg-white' : 'loading !top-0 z-[1000] flex h-screen grow items-center justify-center bg-white'}`}>
      <Image
        src={logo}
        alt="loading"
        className="animate-continuousFadeInOut"
        width={120}
        height={150}
        priority
      />
    </div>
  );
};

export default LoadingView;
