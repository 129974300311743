'use client';

import {backFlower} from '@/assets';
import {
  commonDisableCachingParameters,
  convertEnglishNumbersToArabic,
  renderStars,
  showErrorToast,
} from '@/common/helpers';
import {useApplyCartActions, UseViewPortHeight} from '@/hooks';
import {getCart, getNearestStoreData} from '@/selectors';
import {
  useGetAddOnsQuery,
  useGetAllCategoriesQuery,
  useGetCardDesignsQuery,
} from '@/services';
import {Tab, Tabs} from '@heroui/react';
import {useLocale} from 'next-intl';
import Image from 'next/image';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import Sticky from 'react-stickynode';
import {GiftCard, Reviews} from '.';
import {CustomButton} from '../common';
import AddOns from './AddOns';

const ProductDetails = ({product, t, selectedVariant, setSelectedVariant}) => {
  const locale = useLocale();
  const {height} = UseViewPortHeight();
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});

  const store = useSelector(getNearestStoreData);
  const {data: allCategories, isError: getAllCategoriesError} =
    useGetAllCategoriesQuery(
      {include: 'image', locale, per_page: 100},
      commonDisableCachingParameters,
    );

  const addonsCategory = useMemo(() => {
    return allCategories?.data?.find(category => category.attributes.addons);
  }, [allCategories]);

  const {data: addOns, error: getAddOnsError} = useGetAddOnsQuery(
    {
      locale,
      'filter[branches]': store?.id,
      per_page: 100,
      'filter[taxons]': addonsCategory?.id,
    },
    commonDisableCachingParameters,
  );
  const {data: cardDesigns, error: getCardDesignsError} =
    useGetCardDesignsQuery(
      {locale, 'filter[branches]': store?.id},
      commonDisableCachingParameters,
    );
  const [modalVisible, setModalVisible] = useState(false);
  const [writtenCardData, setWrittenCardData] = useState(null);
  const [selectedAddons, setSelectedAddons] = useState(null);
  const [chosenGiftCard, setChoosenGiftCard] = useState();
  const [isGiftCardShown, setIsGiftCardShown] = useState(false);
  const [selectedTab, setSelectedTab] = useState('productDetails');

  const onHandlingChoosenGiftCard = cardId => {
    setChoosenGiftCard(cardId);
  };

  const cart = useSelector(getCart);
  useEffect(() => {
    setSelectedVariant(product?.productVariants?.[0]);
  }, [product]);

  const onHandlingSelectedAddons = addons => {
    setSelectedAddons(addons);
  };

  const {applyCartAction, isAddingToCartLoading} = useApplyCartActions();

  const handleAddingToCart = async () => {
    try {
      const productAndGiftCardPromises = [];

      if (selectedVariant?.id) {
        productAndGiftCardPromises.push(
          applyCartAction('add', selectedVariant, 1),
        );
      }

      if (
        chosenGiftCard?.id &&
        !cart?.hasWrittenCard &&
        writtenCardData?.get('message') &&
        isGiftCardShown
      ) {
        productAndGiftCardPromises.push(
          applyCartAction('add', chosenGiftCard, 1),
        );
      }

      await Promise.all(productAndGiftCardPromises);

      if (selectedAddons?.length > 0) {
        await selectedAddons.reduce(async (previousPromise, addon) => {
          await previousPromise;
          await applyCartAction('add', addon, 1);
        }, Promise.resolve());
      }
    } catch (error) {
      showErrorToast(t('failed_to_add_items_to_cart'));
    }

    if (writtenCardData?.get('message')) {
      const requestOptions = {
        method: 'POST',
        body: writtenCardData,
        redirect: 'follow',
      };

      fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/api/v2/storefront/written_cards`,
        requestOptions,
      )
        .then(response => response.text())
        .catch(error => showErrorToast(t('video_upload_failed')));
    }
  };

  if (getAddOnsError) {
    throw new Error('Failed to load add-ons.');
  }

  return (
    <>
      {isMobile && (
        <Sticky
          enabled={true}
          top={height - 100}
          bottomBoundary={'#stop-point'}
          innerZ={1000}>
          <div className="w-full justify-end px-6 sm:static sm:flex sm:px-0 sm:pr-6 xl:pr-0">
            <CustomButton
              isLoading={isAddingToCartLoading}
              value={t('add_to_cart')}
              onClickHandling={handleAddingToCart}
              btnStyles="w-full xl:w-[16.875rem] h-[3.313rem] "
            />
          </div>
        </Sticky>
      )}

      <div className="products-container relative hidden sm:flex sm:items-center sm:justify-between sm:pr-6 xl:pr-0">
        <Image
          src={backFlower}
          alt="Back Flower"
          className="absolute top-[-2.5rem] z-[-1] h-[18.75rem] w-[18.75rem] sm:right-[1.375rem] sm:h-[12.5rem] sm:w-[15.625rem] xl:right-[-0.375rem] xl:h-[25rem] xl:w-[26.563rem]"
        />
        <div>
          <p className="mb-2 font-main font-medium text-persianIndigo sm:text-[20px] xl:text-[1.5vw]">
            {product?.productInfo?.name}
          </p>
          <p className="font-main font-medium text-persianIndigo sm:text-[16px] xl:text-[1.1vw]">
            {convertEnglishNumbersToArabic(
              Number(product?.productInfo?.price),
              locale,
            )}{' '}
            {t('sar')}
          </p>
        </div>
        {product?.productInfo?.totalRating > 0 && (
          <div className="flex flex-col items-end">
            <div className="flex w-fit items-center py-2 xl:mb-2">
              {renderStars(product?.productInfo?.totalRating, 4)}
              <span className="ml-2 text-persianIndigo">
                {product?.productInfo?.totalRating}
              </span>
            </div>
            <button
              onClick={() => setModalVisible(true)}
              className="font-secondary font-normal text-persianIndigo underline sm:text-[14px] xl:text-[1vw]">
              {parseInt(product?.totalReviews) === 0
                ? t('no')
                : product?.totalReviews}{' '}
              {parseInt(product?.totalReviews) === 1
                ? t('review')
                : t('reviews')}
            </button>
          </div>
        )}
      </div>
      <hr className="mx-6 mt-8 border-0 border-t border-thistle sm:mx-0 sm:my-6 sm:w-[94%] xl:w-full" />
      <div className="mx-6 sm:mx-0 sm:pr-6 xl:pr-0">
        <Tabs
          aria-label="Options"
          variant="underlined"
          selectedKey={selectedTab}
          fullWidth
          onSelectionChange={setSelectedTab}
          classNames={{
            tabContent:
              'text-[13px] md:text-[16px]  group-data-[selected=true]:text-persianIndigo font-secondary font-normal text-persianIndigo',
            tabList: 'gap-0 border-b-1 border-thistle pb-[15px]',
            cursor: 'w-[102%] bottom-[-15px] bg-persianIndigo',
          }}>
          <Tab key="productDetails" title={t('product_details')}>
            <div
              className={`transition-max-height max-h-screen overflow-hidden duration-500 ease-in-out`}>
              <div
                className={`${product?.productInfo?.productDesc ? '' : 'text-center'} max-h-[40vh] overflow-y-auto break-words p-4`}>
                {product?.productInfo?.productDesc ? (
                  <div
                    className="text-persianIndigo sm:text-[14px] xl:text-[16px]"
                    dangerouslySetInnerHTML={{
                      __html: product?.productInfo?.productDesc,
                    }}
                  />
                ) : (
                  <span className="text-persianIndigo sm:text-[14px] xl:text-[1vw]">
                    {t('no_product_details_available')}
                  </span>
                )}
              </div>
            </div>
          </Tab>
          {product?.productInfo?.productCareTips && (
            <Tab key="careTips" title={t('care_tips')}>
              <div className="max-h-[40vh] overflow-y-auto break-words p-4">
                <div
                  className="text-persianIndigo sm:text-[14px] xl:text-[16px]"
                  dangerouslySetInnerHTML={{
                    __html: product?.productInfo?.productCareTips,
                  }}
                />
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
      <hr className="mx-6 my-6 border-0 border-t border-thistle sm:mx-0 sm:w-[94%] xl:w-full" />
      <GiftCard
        setWrittenCardData={setWrittenCardData}
        onHandlingChoosenGiftCard={onHandlingChoosenGiftCard}
        cardDesigns={cardDesigns}
        isGiftCardShown={isGiftCardShown}
        setIsGiftCardShown={setIsGiftCardShown}
      />
      <AddOns
        addOns={addOns}
        t={t}
        onHandlingSelectedAddons={onHandlingSelectedAddons}
      />

      {isMobile ? (
        <div id="stop-point" className="relative top-[20px] pb-[30px]"></div>
      ) : (
        <div className="w-full justify-end px-6 sm:static sm:flex sm:px-0 sm:pr-6 xl:pr-0">
          <CustomButton
            value={t('add_to_cart')}
            isLoading={isAddingToCartLoading}
            onClickHandling={handleAddingToCart}
            btnStyles="w-full xl:w-[16.875rem] h-[3.313rem] "
          />
        </div>
      )}
      <Reviews
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        product={product}
      />
    </>
  );
};

export default ProductDetails;
