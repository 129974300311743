'use client';
import useEmblaCarousel from 'embla-carousel-react';
import {useLocale} from 'next-intl';
import {useEffect, useState} from 'react';

const UseHandleControlledSwipe = (childrenLength, options = {}) => {
  const locale = useLocale();
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    direction: locale === 'ar' ? 'rtl' : 'ltr',
    startIndex: locale === 'ar' ? childrenLength - 1 : 0,
    ...options,
  });
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);
  useEffect(() => {
    if (emblaApi) {
      const updateScrollButtons = () => {
        setCanScrollNext(emblaApi.canScrollNext());
        setCanScrollPrev(emblaApi.canScrollPrev());
      };
      emblaApi.on('select', updateScrollButtons);
      emblaApi.on('reInit', updateScrollButtons);

      updateScrollButtons();

      return () => {
        emblaApi.off('select', updateScrollButtons);
        emblaApi.off('reInit', updateScrollButtons);
      };
    }
  }, [emblaApi]);

  return {emblaRef, canScrollPrev, canScrollNext, emblaApi};
};

export default UseHandleControlledSwipe;
