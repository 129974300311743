'use client';

import {useTranslations} from 'next-intl';
import React, {useEffect, useRef, useState} from 'react';
import * as fabric from 'fabric';
import QRCode from 'qrcode';
import GoliRegular from '@/assets/fonts/Goli-Regular.otf';
import ArabicFont from '@/assets/fonts/AdobeArabic-Regular.otf';
import {useMediaQuery} from 'react-responsive';
import {logo} from '@/assets';
import NextImage from 'next/image';
import {showErrorToast} from '@/common';

const screenWidth = typeof window !== 'undefined' ? window.innerWidth : 1366;
const imageWidth = (1336 / screenWidth) * 0.83;

const calculatePositionForTo = (
  isArabic,
  isCheckout,
  isMobile,
  isTablet,
  canvas,
) => {
  let position = {};

  if (isCheckout) {
    if (isMobile) {
      position[isArabic ? 'right' : 'left'] = canvas.width / 7;
    } else if (isTablet) {
      position[isArabic ? 'right' : 'left'] = canvas.width / 6;
    } else {
      position[isArabic ? 'right' : 'left'] = canvas.width / 8;
    }
  } else {
    if (isTablet) {
      if (isArabic) {
        position[isArabic ? 'right' : 'left'] = canvas.width * 0.45;
      } else {
        position[isArabic ? 'right' : 'left'] = canvas.width / 5.3;
      }
    } else {
      position[isArabic ? 'right' : 'left'] = canvas.width / 6.5;
    }
  }

  position.top = isCheckout ? (isTablet ? 30 : 15) : isTablet ? 30 : 20;

  if (isCheckout) {
    if (isArabic) {
      if (isTablet) {
        position.fontSize = 25;
      } else {
        position.fontSize = 18;
      }
    } else {
      if (isTablet) {
        position.fontSize = 18;
      } else {
        position.fontSize = 12;
      }
    }
  } else {
    if (isTablet) {
      if (isArabic) {
        position.fontSize = 25;
      } else {
        position.fontSize = 18;
      }
    } else {
      if (isArabic) {
        position.fontSize = 20;
      } else {
        position.fontSize = 14;
      }
    }
  }

  position.fontFamily = isArabic ? 'ArabicFont' : 'Goli';

  position.width = isArabic ? canvas.width * 0.65 : canvas.width * 0.5;

  return position;
};

const calculatePositionForMessage = (
  isArabic,
  isCheckout,
  isMobile,
  isTablet,
  isDoubleExtraLarge,
  canvas,
) => {
  let position = {};

  if (isCheckout) {
    if (isMobile) {
      position.left = canvas.width / 2;
    } else if (isTablet) {
      position.left = canvas.width / 2;
    } else {
      position.left = canvas.width / 1.9;
    }
  } else {
    if (isMobile) {
      if (isArabic) {
        position.left = canvas.width / 2.2;
      } else {
        position.left = canvas.width / 1.75;
      }
    } else if (isTablet) {
      if (isArabic) {
        position.left = canvas.width / 2;
      } else {
        position.left = canvas.width / 1.8;
      }
    } else if (isDoubleExtraLarge) {
      position.left = canvas.width / 2.1;
    } else {
      position.left = canvas.width / 2;
    }
  }

  if (isCheckout) {
    if (isMobile) {
      position.top = canvas.height / 2.4;
    } else if (isTablet) {
      position.top = canvas.height / 2.5;
    } else {
      position.top = canvas.height / 2.3;
    }
  } else {
    if (isMobile) {
      position.top = canvas.height / 2.2;
    } else if (isTablet) {
      position.top = canvas.height / 2.5;
    } else {
      position.top = canvas.height / 2.3;
    }
  }

  if (isCheckout) {
    if (isArabic) {
      if (isTablet) {
        position.fontSize = 20;
      } else {
        position.fontSize = 16;
      }
    } else {
      if (isTablet) {
        position.fontSize = 16;
      } else {
        position.fontSize = 10;
      }
    }
  } else {
    if (isTablet) {
      if (isArabic) {
        position.fontSize = 25;
      } else {
        position.fontSize = 18;
      }
    } else {
      if (isArabic) {
        position.fontSize = 20;
      } else {
        position.fontSize = 14;
      }
    }
  }

  position.fontFamily = isArabic ? 'ArabicFont' : 'Goli';
  position.originX = 'center';
  position.originY = 'center';

  if (isCheckout) {
    if (isArabic) {
      if (isTablet) {
        position.width = canvas.width * 0.6;
      } else {
        position.width = canvas.width * 0.7;
      }
    } else if (isTablet) {
      position.width = canvas.width * 0.6;
    } else {
      position.width = canvas.width * 0.7;
    }
  } else {
    if (isMobile) {
      position.width = canvas.width / 1.43;
    } else if (isTablet) {
      position.width = canvas.width / 1.7;
    } else if (isDoubleExtraLarge) {
      position.width = canvas.width / 1.8;
    } else {
      position.width = canvas.width / 1.5;
    }
  }

  return position;
};

const calculatePositionForFrom = (
  isArabic,
  isCheckout,
  isTablet,
  isDoubleExtraLarge,
  isMobile,
  canvas,
) => {
  let position = {};

  if (isCheckout) {
    if (isTablet) {
      position[isArabic ? 'right' : 'left'] = canvas.width * 0.35;
    } else if (isDoubleExtraLarge) {
      position[isArabic ? 'right' : 'left'] = canvas.width * 0.35;
    } else {
      position[isArabic ? 'right' : 'left'] = canvas.width * 0.3;
    }
  } else {
    if (isTablet) {
      position[isArabic ? 'right' : 'left'] = canvas.width * 0.4;
    } else {
      position[isArabic ? 'right' : 'left'] = canvas.width * 0.35;
    }
  }

  if (isCheckout) {
    if (isArabic) {
      position.top = canvas.height / 1.5;
    } else if (isDoubleExtraLarge) {
      position.top = canvas.height / 1.48;
    } else {
      position.top = canvas.height / 1.45;
    }
  } else {
    if (isMobile) {
      if (isArabic) {
        position.top = canvas.height / 1.45;
      } else {
        position.top = canvas.height / 1.35;
      }
    } else if (isTablet) {
      if (isArabic) {
        position.top = canvas.height / 1.5;
      } else {
        position.top = canvas.height / 1.4;
      }
    } else {
      if (isArabic) {
        position.top = canvas.height / 1.5;
      } else {
        position.top = canvas.height / 1.38;
      }
    }
  }

  if (isArabic) {
    if (isCheckout) {
      if (isTablet) {
        position.fontSize = 25;
      } else {
        position.fontSize = 18;
      }
    } else if (isTablet) {
      position.fontSize = 25;
    } else {
      position.fontSize = 20;
    }
  } else {
    if (isCheckout) {
      if (isTablet) {
        position.fontSize = 18;
      } else {
        position.fontSize = 12;
      }
    } else if (isTablet) {
      position.fontSize = 18;
    } else {
      position.fontSize = 14;
    }
  }

  position.fontFamily = isArabic ? 'ArabicFont' : 'Goli';

  position.width = isArabic ? canvas.width * 0.75 : canvas.width * 0.5;

  return position;
};

const GiftCardPreview = ({
  image,
  formData,
  setImageURL,
  isCheckout,
  urlExists,
}) => {
  const canvasRef = useRef(null);
  const fabricCanvasRef = useRef(null);
  const t = useTranslations();
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [qrCode, setQrCode] = useState('');
  const isTablet = useMediaQuery({query: '(max-width: 1024px)'});
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});
  const isDoubleExtraLarge = useMediaQuery({query: '(min-width: 1536px)'});

  const {to = '', from = '', message = ''} = formData || {};

  const generateQRCode = async () => {
    const qrCodeDataURL = await QRCode.toDataURL('https://www.google.com', {
      color: {
        dark: '#000000',
        light: '#00000000',
      },
    });
    setQrCode(qrCodeDataURL);
  };

  useEffect(() => {
    generateQRCode();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      window.location.reload();
    };

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobileDevice = /android|iPhone|iPad|iPod/i.test(userAgent);

    if (!isMobileDevice) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const updateCanvasSize = () => {
      const canvasContainer = canvasRef.current;
      if (canvasContainer) {
        const width = canvasContainer.offsetWidth;
        const height = width / 1;
        setCanvasDimensions({width, height});
      }
    };

    updateCanvasSize();
  }, []);

  useEffect(() => {
    const loadFontAndDraw = async () => {
      const canvasEl = canvasRef.current;
      if (!canvasEl || !canvasDimensions.width || !canvasDimensions.height)
        return;

      if (fabricCanvasRef.current) {
        fabricCanvasRef.current.dispose();
        fabricCanvasRef.current = null;
      }

      const fontGoli = new FontFace('Goli', `url(${GoliRegular})`);
      const fontArabic = new FontFace('ArabicFont', `url(${ArabicFont})`);

      await Promise.all([fontGoli.load(), fontArabic.load()]);
      document.fonts.add(fontGoli);
      document.fonts.add(fontArabic);

      const canvas = new fabric.Canvas(canvasEl);
      fabricCanvasRef.current = canvas;

      const img = new Image();
      img.src = image?.default?.src || `${image}`;
      img.crossOrigin = 'Anonymous';

      img.onload = async () => {
        const fabricImage = new fabric.FabricImage(img);

        fabricImage.scaleToWidth(canvas.width * imageWidth);
        fabricImage.scaleToHeight(canvas.height);

        fabricImage.selectable = false;

        canvas.add(fabricImage);
        canvas.centerObject(fabricImage);

        const isArabic = /[\u0600-\u06FF]/.test(message);

        const addText = (text, options) => {
          const textObj = new fabric.Textbox(text, {
            ...options,
            textAlign: isArabic ? 'right' : 'left',
            lineHeight: isArabic ? 1 : 1.75,
            selectable: false,
          });
          canvas.add(textObj);
          canvas.renderAll();
        };

        if (to) {
          addText(
            `${isArabic ? 'إلى' : 'To'}: ${to}`,
            calculatePositionForTo(
              isArabic,
              isCheckout,
              isMobile,
              isTablet,
              canvas,
            ),
          );
        }

        addText(
          message,
          calculatePositionForMessage(
            isArabic,
            isCheckout,
            isMobile,
            isTablet,
            isDoubleExtraLarge,
            canvas,
          ),
        );

        if (from) {
          addText(
            `${isArabic ? 'من' : 'From'}: ${from}`,
            calculatePositionForFrom(
              isArabic,
              isCheckout,
              isTablet,
              isDoubleExtraLarge,
              isMobile,
              canvas,
            ),
          );
        }

        const url = canvas.toDataURL('image/png');
        setImageURL(url);
      };

      img.onerror = () => {
        showErrorToast(t('image_failed_to_load'));
      };
    };

    loadFontAndDraw();

    return () => {
      if (fabricCanvasRef.current) {
        fabricCanvasRef.current.dispose();
        fabricCanvasRef.current = null;
      }
    };
  }, [image, to, from, message, canvasDimensions, window.innerWidth]);

  useEffect(() => {
    if (canvasRef?.current !== null) {
      const timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [canvasRef?.current]);

  return isTablet ? (
    <div className="xl:w-1/2">
      <div className="relative">
        <canvas
          ref={canvasRef}
          width={canvasDimensions.width}
          height={canvasDimensions.height}
          className="h-[11.313rem] w-full border-2 border-dashed border-thistle object-fill p-2 xl:h-[18rem]"
        />
        {qrCode && urlExists && (
          <div
            className={`absolute ${isMobile && !isCheckout ? 'bottom-[6%]' : 'bottom-[8%]'} right-[50%] h-14 w-14`}>
            <NextImage
              src={qrCode}
              alt="QR Code"
              className="h-full w-full object-contain"
            />
          </div>
        )}
        {isLoading && (
          <div className="absolute inset-0 flex grow items-center justify-center bg-white bg-opacity-75">
            <NextImage
              src={logo}
              alt="loading"
              className="animate-continuousFadeInOut"
              width={60}
              height={75}
            />
          </div>
        )}
      </div>
      <p className="mt-3 text-center font-secondary text-persianIndigo sm:block sm:text-[14px] xl:text-[1vw]">
        {t('gift_card_preview')}
      </p>
    </div>
  ) : (
    <div className="xl:w-1/2">
      <div className="relative">
        <canvas
          ref={canvasRef}
          width={canvasDimensions.width}
          height={isCheckout ? 218 : 288}
          className="h-[11.313rem] w-full border-2 border-dashed border-thistle object-fill p-2 xl:h-[18rem]"
        />
        {qrCode && urlExists && (
          <div className="absolute bottom-[8%] right-[50%] h-10 w-10">
            <NextImage
              src={qrCode}
              alt="QR Code"
              className="h-full w-full object-contain"
            />
          </div>
        )}
        {isLoading && (
          <div className="absolute inset-0 flex grow items-center justify-center bg-white bg-opacity-75">
            <NextImage
              src={logo}
              alt="loading"
              className="animate-continuousFadeInOut"
              width={60}
              height={75}
            />
          </div>
        )}
      </div>
      <p className="mt-3 text-center font-secondary text-persianIndigo sm:block sm:text-[14px] xl:text-[1vw]">
        {t('gift_card_preview')}
      </p>
    </div>
  );
};

export default GiftCardPreview;
