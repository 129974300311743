'use client';

import useEmblaCarousel from 'embla-carousel-react';
import {useLocale} from 'next-intl';
import {useCallback, useEffect, useRef} from 'react';

const UseHandleAutoSwipe = duration => {
  const locale = useLocale();

  const [emblaRef, emblaApi] = useEmblaCarousel({
    axis: 'x',
    loop: true,
    dragFree: false,
    direction: locale === 'ar' ? 'rtl' : 'ltr',
  });
  const autoplayInterval = useRef(null);

  const startAutoplay = useCallback(() => {
    if (!emblaApi) return;
    autoplayInterval.current = setInterval(() => {
      if (emblaApi.canScrollNext()) {
        emblaApi.scrollNext();
      } else {
        emblaApi.scrollTo(0);
      }
    }, duration);
  }, [emblaApi]);

  const stopAutoplay = useCallback(() => {
    if (autoplayInterval.current) {
      clearInterval(autoplayInterval.current);
    }
  }, []);
  useEffect(() => {
    if (emblaApi) {
      startAutoplay();
      emblaApi.on('reInit', startAutoplay);
    }

    return () => stopAutoplay();
  }, [emblaApi, startAutoplay, stopAutoplay]);

  return {emblaRef};
};

export default UseHandleAutoSwipe;
