import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isSignUpOpen: false,
  isLogInOpen: false,
  isUpdateCreditCardOpen: false,
  isDeleteCreditCardOpen: false,
  isDeliveryMapModalOpen: false,
  isOtpModalOpen: false,
  isGeneralOtpModalOpen: false,
  otpModalState: null,
  isForgetPasswordModalOpen: false,
  isDeleteUserAddressModalOpen: false,
  isNewPasswordUpdateModalOpen: false,
  isResetPasswordModalOpen: false,
  chosenShoppingMethodId: null,
};

export const modalActionsSlice = createSlice({
  name: 'modalActions',
  initialState,
  reducers: {
    openSignUp: state => {
      state.isSignUpOpen = true;
    },
    closeSignUp: state => {
      state.isSignUpOpen = false;
    },
    openLogIn: state => {
      state.isLogInOpen = true;
    },
    closeLogIn: state => {
      state.isLogInOpen = false;
    },
    openUpdateCreditCard: state => {
      state.isUpdateCreditCardOpen = true;
    },
    closeUpdateCreditCard: state => {
      state.isUpdateCreditCardOpen = false;
    },
    openDeleteCreditCard: state => {
      state.isDeleteCreditCardOpen = true;
    },
    closeDeleteCreditCard: state => {
      state.isDeleteCreditCardOpen = false;
    },
    openDeliveryMapModal: state => {
      state.isDeliveryMapModalOpen = true;
    },
    closeDeliveryMapModal: state => {
      state.isDeliveryMapModalOpen = false;
    },
    openOtpModal: (state, action) => {
      state.isOtpModalOpen = true;
      state.otpModalState = action.payload;
    },
    closeOtpModal: state => {
      state.isOtpModalOpen = false;
    },
    openForgetPassword: state => {
      state.isForgetPasswordModalOpen = true;
    },
    closeForgetPassword: state => {
      state.isForgetPasswordModalOpen = false;
    },
    openDeleteUserAddressModal: state => {
      state.isDeleteUserAddressModalOpen = true;
    },
    closeDeleteUserAddressModal: state => {
      state.isDeleteUserAddressModalOpen = false;
    },
    openNewPasswordUpdateModal: state => {
      state.isNewPasswordUpdateModalOpen = true;
    },
    closeNewPasswordUpdateModal: state => {
      state.isNewPasswordUpdateModalOpen = false;
    },
    openResetPasswordModal: state => {
      state.isResetPasswordModalOpen = true;
    },
    closeResetPasswordModal: state => {
      state.isResetPasswordModalOpen = false;
    },
    setChosenShoppingMethodId: (state, action) => {
      state.chosenShoppingMethodId = action.payload;
    },
    closeGeneralOtpModal: state => {
      state.isGeneralOtpModalOpen = false;
    },
    openGeneralOtpModal: state => {
      state.isGeneralOtpModalOpen = true;
    },
  },
});

export const {
  openSignUp,
  closeSignUp,
  openLogIn,
  closeLogIn,
  openDeleteCreditCard,
  openUpdateCreditCard,
  closeDeleteCreditCard,
  closeUpdateCreditCard,
  openDeliveryMapModal,
  closeDeliveryMapModal,
  closeOtpModal,
  openOtpModal,
  openForgetPassword,
  closeForgetPassword,
  openDeleteUserAddressModal,
  closeDeleteUserAddressModal,
  openNewPasswordUpdateModal,
  closeNewPasswordUpdateModal,
  openResetPasswordModal,
  closeResetPasswordModal,
  setChosenShoppingMethodId,
  closeGeneralOtpModal,
  openGeneralOtpModal,
} = modalActionsSlice.actions;
export const modalActionsReducer = modalActionsSlice.reducer;
