import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  selectedImageURL: null,
  giftCardsCarouselIndex: 0,
};

const giftCardSlice = createSlice({
  name: 'giftCard',
  initialState,
  reducers: {
    setSelectedImageURL(state, action) {
      state.selectedImageURL = action.payload;
    },
    setGiftCardsCarouselInedx(state, action) {
      state.giftCardsCarouselIndex = action.payload;
    },
  },
});

export const {setSelectedImageURL, setGiftCardsCarouselInedx} =
  giftCardSlice.actions;
export const giftCardReducer = giftCardSlice.reducer;
