'use client';

import {useRouter as useNextRouter, usePathname} from '@/utils/navigation';
import NProgress from 'nprogress';
import {useCallback} from 'react';

const useRouter = () => {
  const router = useNextRouter();
  const pathname = usePathname();

  const replace = useCallback(
    (href, options) => {
      href !== pathname && NProgress.start();
      router.replace(href, options);
    },
    [router, pathname],
  );

  const push = useCallback(
    (href, options) => {
      href !== pathname && NProgress.start();
      router.push(href, options);
    },
    [router, pathname],
  );

  return {
    ...router,
    replace,
    push,
  };
};

export default useRouter;
