'use client';

import {share} from '@/assets';
import Image from 'next/image';
import React, {useMemo} from 'react';
import {CustomButton} from '../common';
import {showErrorToast, showSuccessToast} from '@/common';
import {useLocale, useTranslations} from 'next-intl';
import {usePathname} from '@/utils/navigation';
import {GoHeart, GoHeartFill} from 'react-icons/go';
import {UseAddOrRemoveFav} from '@/hooks';

const ProductActions = ({selectedVariant, product}) => {
  const t = useTranslations();
  const pathName = usePathname();
  const locale = useLocale();
  const {
    applyOrRemoveFavItem,
    localFavData,
    isFavProductsAreLoading,
    favToken,
  } = UseAddOrRemoveFav();

  const isProductInFavorites = useMemo(() => {
    return localFavData?.find(
      item => item?.product.id === product?.productInfo?.id,
    );
  }, [localFavData, product?.productInfo?.id]);

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(
        `${process.env.NEXT_PUBLIC_FRONT_END_SERVER}/${locale}${pathName}`,
      )
      .then(
        () => {
          showSuccessToast(t('product_link_copied_to_clipboard'));
        },
        err => {
          showErrorToast(t('failed_to_copy_product_link'));
        },
      );
  };
  const handleAddOrRemoveFromFav = () => {
    applyOrRemoveFavItem(
      isProductInFavorites,
      selectedVariant?.id,
      product?.productInfo?.id,
    );
  };

  return (
    <div className="absolute top-[16px] flex flex-col gap-4 ltr:left-[40px] ltr:sm:left-[16px] rtl:right-[40px] rtl:sm:right-[16px]">
      <CustomButton
        iconOnly
        onClickHandling={handleCopyLink}
        btnStyles="bg-white bg-opacity-50 !p-1 !rounded-sm cursor-pointer"
        value={
          <Image
            src={share}
            width={50}
            height={50}
            className="h-[20px] w-[20px]"
            alt="share"
          />
        }
      />
      {favToken && (
        <CustomButton
          iconOnly
          isLoading={isFavProductsAreLoading}
          onClickHandling={handleAddOrRemoveFromFav}
          btnStyles="bg-white bg-opacity-50 !p-1 !rounded-sm cursor-pointer"
          value={
            isProductInFavorites ? (
              <GoHeartFill className="text-[22px] font-bold text-persianIndigo" />
            ) : (
              <GoHeart className="text-[22px] font-bold text-persianIndigo" />
            )
          }
        />
      )}
    </div>
  );
};

export default ProductActions;
