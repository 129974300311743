import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isWalletPaymentChosen: false,
  cardAmount: 0,
  isFinishingPayment: false,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setIsWalletPaymentChosen(state, action) {
      state.isWalletPaymentChosen = action.payload;
    },
    setCardAmount(state, action) {
      state.cardAmount = action.payload;
    },
    setIsFinishingPayment(state, action) {
      state.isFinishingPayment = action.payload;
    },
  },
});

export const {setIsWalletPaymentChosen, setCardAmount, setIsFinishingPayment} =
  paymentSlice.actions;
export default paymentSlice.reducer;
