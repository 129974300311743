'use client';

import {convertEnglishNumbersToArabic, renderStars} from '@/common';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalContent,
  Progress,
  ScrollShadow,
} from '@heroui/react';
import {useLocale, useTranslations} from 'next-intl';
import {Fragment} from 'react';
import {FaStar} from 'react-icons/fa';
import {CustomButton} from '../common';

const Reviews = ({visible, onClose, product}) => {
  const t = useTranslations();
  const locale = useLocale();

  return (
    <Modal
      isOpen={visible}
      size="5xl"
      onClose={onClose}
      classNames={{closeButton: 'hidden', wrapper: 'items-center'}}
      className="mx-6 h-[80vh] p-8">
      <ModalContent>
        <ModalHeader className="flex items-center justify-center font-main text-[22px] font-medium text-persianIndigo sm:justify-between sm:text-[34px]">
          <span>{t('reviews')}</span>
          <CustomButton
            btnType="tertiary"
            value={'X'}
            btnStyles="hidden sm:inline-flex text-[22px] text-persianIndigo !hover:!bg-none !p-0"
            onClickHandling={onClose}
          />
        </ModalHeader>
        <ModalBody className="h-full !p-0">
          {product?.reviewsInfo.length > 0 ? (
            <div className="flex h-full flex-col sm:flex-row">
              <div className="mb-4 sm:mr-8 sm:h-[80%] sm:w-2/5 sm:border-r sm:border-thistle sm:pr-8">
                <h3 className="mb-4 font-secondary text-[16px] font-normal text-persianIndigo">
                  {t('total_reviews')}:{' '}
                  <span className="font-bold">
                    {convertEnglishNumbersToArabic(
                      product?.totalReviews,
                      locale,
                    )}
                  </span>
                </h3>
                <div className="mb-4 flex items-center gap-3">
                  <h3 className="font-secondary text-[16px] font-normal text-persianIndigo">
                    {t('avg_rating')}:
                  </h3>
                  <div className="mb-2 flex w-fit items-center bg-lightPurple px-3 py-2">
                    {renderStars(product?.productInfo?.totalRating)}
                    <span className="ml-2 text-persianIndigo">
                      {convertEnglishNumbersToArabic(
                        product?.productInfo?.totalRating,
                        locale,
                      )}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="mb-2 flex items-center justify-between">
                    <div className="flex items-center gap-1">
                      <span className="text-persianIndigo">
                        {convertEnglishNumbersToArabic(5, locale)}
                      </span>
                      <FaStar className="text-persianIndigo" />
                    </div>
                    <Progress
                      size="sm"
                      value={
                        product?.ratingPercentages
                          ?.totalReviewsWithRating5Percentage
                      }
                      color="default"
                      className="w-[70%]"
                      classNames={{
                        indicator: 'bg-persianIndigo',
                        track: 'bg-thistle',
                      }}
                    />
                    <span className="w-[2.5rem] text-right text-persianIndigo">
                      {
                        product?.ratingPercentages
                          ?.totalReviewsWithRating5Percentage
                      }
                      %
                    </span>
                  </div>
                  <div className="mb-2 flex items-center justify-between">
                    <div className="flex items-center gap-1">
                      <span className="text-persianIndigo">
                        {convertEnglishNumbersToArabic(4, locale)}
                      </span>
                      <FaStar className="text-persianIndigo" />
                    </div>
                    <Progress
                      size="sm"
                      value={
                        product?.ratingPercentages
                          ?.totalReviewsWithRating4Percentage
                      }
                      color="default"
                      className="w-[70%]"
                      classNames={{
                        indicator: 'bg-persianIndigo',
                        track: 'bg-thistle',
                      }}
                    />
                    <span className="w-[2.5rem] text-right text-persianIndigo">
                      {
                        product?.ratingPercentages
                          ?.totalReviewsWithRating4Percentage
                      }
                      %
                    </span>
                  </div>
                  <div className="mb-2 flex items-center justify-between">
                    <div className="flex items-center gap-1">
                      <span className="text-persianIndigo">
                        {convertEnglishNumbersToArabic(3, locale)}
                      </span>
                      <FaStar className="text-persianIndigo" />
                    </div>
                    <Progress
                      size="sm"
                      value={
                        product?.ratingPercentages
                          ?.totalReviewsWithRating3Percentage
                      }
                      color="default"
                      className="w-[70%]"
                      classNames={{
                        indicator: 'bg-persianIndigo',
                        track: 'bg-thistle',
                      }}
                    />
                    <span className="w-[2.5rem] text-right text-persianIndigo">
                      {
                        product?.ratingPercentages
                          ?.totalReviewsWithRating3Percentage
                      }
                      %
                    </span>
                  </div>
                  <div className="mb-2 flex items-center justify-between">
                    <div className="flex items-center gap-1">
                      <span className="text-persianIndigo">
                        {convertEnglishNumbersToArabic(2, locale)}
                      </span>
                      <FaStar className="text-persianIndigo" />
                    </div>
                    <Progress
                      size="sm"
                      value={
                        product?.ratingPercentages
                          ?.totalReviewsWithRating2Percentage
                      }
                      color="default"
                      className="w-[70%]"
                      classNames={{
                        indicator: 'bg-persianIndigo',
                        track: 'bg-thistle',
                      }}
                    />
                    <span className="w-[2.5rem] text-right text-persianIndigo">
                      {
                        product?.ratingPercentages
                          ?.totalReviewsWithRating2Percentage
                      }
                      %
                    </span>
                  </div>
                  <div className="mb-2 flex items-center justify-between">
                    <div className="flex items-center gap-1">
                      <span className="text-persianIndigo">
                        {convertEnglishNumbersToArabic(1, locale)}
                      </span>
                      <FaStar className="text-persianIndigo" />
                    </div>
                    <Progress
                      size="sm"
                      value={
                        product?.ratingPercentages
                          ?.totalReviewsWithRating1Percentage
                      }
                      color="default"
                      className="w-[70%]"
                      classNames={{
                        indicator: 'bg-persianIndigo',
                        track: 'bg-thistle',
                      }}
                    />
                    <span className="w-[40px] text-right text-persianIndigo">
                      {
                        product?.ratingPercentages
                          ?.totalReviewsWithRating1Percentage
                      }
                      %
                    </span>
                  </div>
                </div>
              </div>
              <ScrollShadow
                className="mb-4 overflow-y-auto sm:h-[80%] sm:w-3/5"
                hideScrollBar>
                {product?.reviewsInfo.map((review, index) => (
                  <Fragment key={index}>
                    <div>
                      <p className="mb-4 font-main text-[20px] font-medium text-persianIndigo">
                        {review.reviewerName}
                      </p>
                      <div className="mb-4 flex w-fit items-center bg-lightPurple px-3 py-2">
                        {renderStars(review.reviewerRate)}
                        <span
                          className={`${locale === 'ar' ? 'mr-2' : 'ml-2'} text-persianIndigo`}>
                          {convertEnglishNumbersToArabic(
                            review.reviewerRate,
                            locale,
                          )}
                        </span>
                      </div>
                      <p className="text-persianIndigo">
                        {review.reviewerComment}
                      </p>
                    </div>
                    <hr className="my-4 w-full border-0 border-t border-thistle sm:w-[80%]" />
                    {review.reply && (
                      <>
                        <div>
                          <p className="mb-4 font-main text-[20px] font-medium text-persianIndigo">
                            {review.reply?.adminName}
                          </p>
                          <p className="text-persianIndigo">
                            {review.reply?.body}
                          </p>
                        </div>
                        <hr className="my-4 w-full border-0 border-t border-thistle sm:w-[80%]" />
                      </>
                    )}
                  </Fragment>
                ))}
              </ScrollShadow>
            </div>
          ) : (
            <div className="flex h-full flex-col items-center justify-center">
              <h2 className="font-secondary text-2xl text-persianIndigo">
                {t('no_reviews')}
              </h2>
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Reviews;
